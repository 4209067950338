/**
 * 搜索头
 */
//  开票记录
export const searchFileds = [
  {
    field: "driver_name",
    label: "司机姓名",
    // type: "select-data-search",
    type: "input",
    props: {
      placeholder: ["请输入司机姓名"]
    },
    options: [],
    iw: 200
  }
];
