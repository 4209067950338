<template>
  <page-layout class="ma-24">
    <!-- 搜索头 -->
    <search-header
      :schemaData="searchFileds"
      @getDataList="handleFormSearch"
      style="padding-left: 0px;"
    />
    <!-- 新增司机账户按钮 -->
    <a-button
      v-if="$store.getters.roles.includes('DriverAdd')"
      type="primary"
      class="my-16"
      @click="visible = true"
      >新增司机账户</a-button
    >
    <a-button type="primary" class="my-16 ml-6" @click="visible2 = true"
      >同步司机信息</a-button
    >
    <!-- 新增司机弹窗 -->
    <a-modal
      v-model:visible="visible"
      title="新增司机"
      @ok="handleOk"
      @cancel="handleCancel"
      :confirmLoading="loading"
      :destroyOnClose="true"
    >
      <a-form
        ref="formRef"
        :model="formState"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-item ref="name" label="司机姓名" name="name">
          <a-input
            v-model:value="formState.name"
            placeholder="请输入司机姓名"
          />
        </a-form-item>
        <a-form-item ref="mobile" label="司机手机号" name="mobile">
          <a-input
            v-model:value="formState.mobile"
            placeholder="请输入司机手机号"
          />
        </a-form-item>
        <a-form-item
          ref="identity_no"
          label="司机身份证号码"
          name="identity_no"
        >
          <a-input
            v-model:value="formState.identity_no"
            placeholder="请输入司机身份证号码"
          />
        </a-form-item>
        <a-form-item ref="sp_code" label="开卡服务商" name="sp_code">
          <a-select
            v-model:value="formState.sp_code"
            placeholder="请选择开卡服务商"
          >
            <a-select-option value="ZHAOYOU">找油网</a-select-option>
            <a-select-option value="NUCARF">万金油</a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </a-modal>
    <!-- 同步司机信息 -->
    <a-modal
      v-model:visible="visible2"
      title="同步司机信息"
      :rules="rules2"
      @ok="handleOk2"
      @cancel="handleCancel2"
      :confirmLoading="loading2"
      :destroyOnClose="true"
      :loading="loading2"
    >
      <a-form
        ref="formRef2"
        :model="formState2"
        :rules="rules2"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-item label="服务商列表" name="spCode">
          <a-select
            v-model:value="formState2.spCode"
            placeholder="请选择服务商"
          >
            <a-select-option
              v-for="item of serviceProvideList"
              :key="item.value"
              :value="item.value"
              >{{ item.label }}</a-select-option
            >
          </a-select>
        </a-form-item>
      </a-form>
    </a-modal>
    <!-- 列表 -->
    <div class="bg-white">
      <p-table
        rowKey="id"
        :dataSource="dataSource"
        class="bg-white"
        dataKey="modal"
      >
        <p-t-column type="seq" width="60" title="序号" />
        <p-t-column field="driver_name" width="120" title="司机姓名" />
        <p-t-column field="driver_mobile" min-width="140" title="手机号" />
        <p-t-column
          field="balance"
          min-width="120"
          title="账户余额（元）"
          v-slot="{ row }"
          >{{ (row.balance / 100).toFixed(2) }}</p-t-column
        >
        <p-t-column
          field="payment_amount"
          min-width="120"
          title="消费金额（元）"
          v-slot="{ row }"
          >{{ (row.payment_amount / 100).toFixed(2) }}</p-t-column
        >
        <p-t-column field="order_count" min-width="120" title="订单数" />
        <p-t-column field="oil_quantity" min-width="120" title="加油量（升）" />
        <p-t-column
          field="gas_quantity"
          min-width="120"
          title="加气量（千克）"
        />
        <p-t-column field="card_count" min-width="120" title="油卡数量" />
        <p-t-column min-width="240" title="操作" fixed="right">
          <template v-slot="{ row }">
            <a-button
              type="link"
              style="padding:0;"
              @click="handleOrderDetail(row)"
              >账户详情</a-button
            >
            <a-button
              v-if="$store.getters.roles.includes('ConsumerOrders')"
              type="link"
              @click="handleViewOrder(row)"
              >查看订单</a-button
            >
          </template>
        </p-t-column>
      </p-table>
    </div>
  </page-layout>
</template>
<script>
import { defineComponent, reactive, ref, createVNode } from "vue";
import { useRouter } from "vue-router";
import { getInitTable } from "@/hooks/form-hook";
import { Table } from "@wlhy-web-components/common";
import { searchFileds } from "./constants/index";
import { message } from "ant-design-vue";
import useSearchForm from "@/hooks/UseSearchForm";
import {
  useGasCardPageApi,
  useGasDriverBindApi,
  useEnumServiceProvideListApi,
  useGasDriverSyncApi
} from "@/apis/oil";

//司机信息同步逻辑
const useDriverSync = () => {
  //服务商列表
  const enumServiceProvideList = useEnumServiceProvideListApi();
  const serviceProvideList = ref();
  (async () => {
    serviceProvideList.value = await enumServiceProvideList();
  })();

  const formState2 = ref({
    spCode: undefined
  });
  const loading2 = ref(false);

  //规则
  const rules2 = {
    spCode: [
      {
        required: true,
        message: "请选择服务商",
        trigger: "blur"
      }
    ]
  };

  const formRef2 = ref();
  const visible2 = ref(false);

  //请求同步接口
  const gasDriverSync = useGasDriverSyncApi();
  const handleOk2 = () => {
    formRef2.value
      .validate()
      .then(async () => {
        loading2.value = true;
        let res = await gasDriverSync({ sp_code: formState2.value.spCode });
        message.success(res);
        visible2.value = false;
      })
      .catch(error => {
        console.log("error", error);
        loading2.value = false;
      });
  };
  // 取消函数;
  const handleCancel2 = () => {
    visible2.value = false;
    formRef2.value.resetFields();
    loading2.value = false;
  };

  return {
    serviceProvideList,
    formRef2,
    formState2,
    loading2,
    rules2,
    visible2,
    handleOk2,
    handleCancel2
  };
};

export default defineComponent({
  name: "account-driver",
  setup() {
    const router = useRouter();
    const tableDataSource = reactive(getInitTable());
    //表格搜索相关
    const { filter, handleFormSearch } = useSearchForm(searchFileds);
    //表格数据接口
    const gasCardPage = useGasCardPageApi();
    const getConsultingList = Table.useTableConfig({
      filter,
      dataSource: tableDataSource,
      async fetchData() {
        const data = {
          owner_type: "DRIVER",
          ...filter.value,
          size: tableDataSource.meta.pageSize,
          page: tableDataSource.meta.currentPageNo
        };
        data.driver_id = data.driver_id ? [data.driver_id] : data.driver_id;
        const res = await gasCardPage(data);
        return { records: res.list, total: res.total };
      }
    });

    // 跳转到开票记录详情
    const handleActionClick = row => {
      console.log(row);
      let details = {
        id: row.id,
        name: row.apply_user,
        time: row.apply_time,
        total: row.amount
      };
      router.push({
        path: "/invoice/invoice-detail",
        query: details
      });
    };
    //查看订单跳转
    const handleViewOrder = row => {
      let details = {
        driver_ids: row.driver_id,
        sp_code: row.sp_code
      };
      router.push({
        path: "/oilCard/consumer-orders",
        query: details
      });
    };
    //账户详情跳转
    const handleOrderDetail = row => {
      console.log(row);
      let details = {
        driver_id: row.driver_id
      };
      router.push({
        path: "/oilCard/account-driver-detail",
        query: details
      });
    };
    //新增司机
    const formRef = ref();
    const visible = ref(false);
    const formState = ref({ name: "", mobile: "", sp_code: "" });
    //汉字验证规则[\u4e00-\u9fa5]
    const validateName = () => {
      // alert(111);
      if (!formState.value.name) {
        return Promise.reject("姓名不能为空");
      } else {
        let reg = /^[\u4e00-\u9fa5]{2,4}$/;
        if (reg.test(formState.value.name)) {
          return Promise.resolve();
        } else {
          return Promise.reject("姓名不正确");
        }
      }
    };
    const rules = {
      name: [
        {
          required: true,
          validator: validateName,
          trigger: "blur"
        }
      ],
      mobile: [
        {
          required: true,
          message: "手机号码不正确",
          pattern: /(13|14|15|17|18|19)[0-9]{9}/,
          trigger: "blur"
        }
      ],
      identity_no: [
        {
          required: true,
          message: "身份证号码不正确",
          pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
          trigger: "blur"
        }
      ],
      sp_code: [
        {
          required: true,
          message: "请选择开卡服务商",
          trigger: "change"
        }
      ]
    };
    const gasDriverBind = useGasDriverBindApi();

    const successMsg = data => {
      if (data === "新增司机请求已受理, 请稍后") {
        message.success(data);
        formRef.value.resetFields();
        loading.value = false;
        visible.value = false;
      } else {
        message.warning({
          content: data || "提交失败",
          icon: createVNode("img", {
            src:
              "http://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/vas/admin/public/fail_face.png",
            style: "width:16px;height:16px;margin:0 5px 5px 0 "
          })
        });
        loading.value = false;
      }
    };

    const errorMsg = () => {
      loading.value = false;
    };
    //modal按钮事件
    const loading = ref(false);
    const handleOk = () => {
      formRef.value
        .validate()
        .then(async () => {
          loading.value = true;
          let res = await gasDriverBind(formState.value);
          console.log("res", res);
          successMsg(res);
        })
        .catch(error => {
          console.log("error", error);
          errorMsg();
        });
    };
    const handleCancel = () => {
      formRef.value.resetFields();
    };

    //同步司机信息
    const DriverSync = useDriverSync();
    return {
      loading,
      formRef,
      rules,
      visible,
      labelCol: { span: 6 },
      handleOk,
      handleCancel,
      formState,
      wrapperCol: { span: 18 },
      searchFileds,
      getConsultingList,
      handleViewOrder,
      handleOrderDetail,
      handleFormSearch,
      handleActionClick,
      dataSource: tableDataSource,
      ...DriverSync
    };
  }
});
</script>
